import i18n from 'services/i18n'
import moment from 'moment'

import { parseCurrency, getCurrencySymbolFromCode } from 'helpers/currency'
import {
  formatDateAxisMonthDayFull,
  getDateRange,
  groupDataByMonth,
  groupDataByWeek,
  groupDataByWeekAndTrim
} from 'helpers/charts'
import {
  humanizeLongNumber,
  humanizeLongNumberWithLocale
} from 'helpers/formatters'
import { sumKeys, avgKeys, avgRating, avgKeysCurrency } from 'helpers/objects'
import { CHART_TOOLTIP_CONFIG } from 'constants/competitive_intelligence/common'

export const getAxisLabel = metric => {
  switch (metric) {
    case 'revenue':
      return i18n.t('common:MetricName.revenue', 'Revenue')
    case 'units_sold':
      return i18n.t('common:MetricName.unitsSold', 'Units Sold')
    case 'avg_price':
      return i18n.t('common:MetricName.avgPrice', 'Avg Price')
    case 'star_rating':
      return i18n.t('common:MetricName.starRating', 'Star Rating')
    case 'reviews':
      return i18n.t('common:MetricName.reviews', 'Reviews')
    default:
      return i18n.t('common:MetricName.revenue', 'Revenue')
  }
}

export const getChartTitle = metric => {
  switch (metric) {
    case 'revenue':
      return i18n.t(
        'competitive_intelligence:OverviewTab.revenueOverTime',
        'Revenue Over Time'
      )
    case 'units_sold':
      return i18n.t(
        'competitive_intelligence:OverviewTab.unitsSoldOverTime',
        'Units Sold Over Time'
      )
    case 'avg_price':
      return i18n.t(
        'competitive_intelligence:OverviewTab.avgPriceOverTime',
        'Avg Price Over Time'
      )
    case 'star_rating':
      return i18n.t(
        'competitive_intelligence:OverviewTab.starRatingOverTime',
        'Star Rating Over Time'
      )
    case 'reviews':
      return i18n.t(
        'competitive_intelligence:OverviewTab.reviewsOverTime',
        'Reviews Over Time'
      )
    default:
      return i18n.t(
        'competitive_intelligence:OverviewTab.revenueOverTime',
        'Revenue Over Time'
      )
  }
}

export const metricFormatter = (value, metric) => {
  if (metric === 'revenue') {
    return parseCurrency(value, 'USD', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
  }

  if (metric === 'avg_price') {
    return parseCurrency(value, 'USD', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  if (metric === 'star_rating') {
    return humanizeLongNumberWithLocale(parseFloat(value).toFixed(1))
  }

  return humanizeLongNumberWithLocale(Math.round(value))
}
export const metricYAxisFormatter = (value, metric) => {
  const formatted = `${humanizeLongNumber(value)}`.toUpperCase()

  if (['revenue', 'avg_price'].includes(metric)) {
    return `${getCurrencySymbolFromCode()}${formatted}`
  }

  if (['units_sold', 'reviews'].includes(metric)) {
    return formatted
  }

  return parseFloat(value).toFixed(0)
}

export const formatChartData = ({ data, interval, trim = false }) => {
  if (!data || data.length === 0) return []

  if (interval === 'daily') {
    return data.map(item => {
      const formattedDate = formatDateAxisMonthDayFull(item.date)
      return {
        ...item,
        formattedDate
      }
    })
  }

  let groupedData = {}

  if (interval === 'weekly') {
    groupedData = trim
      ? groupDataByWeekAndTrim(data, 'x')
      : groupDataByWeek(data, 'x')
  } else if (interval === 'monthly') {
    groupedData = groupDataByMonth(data, 'x')
  }

  const dataKeys = Object.keys(data[0]).filter(key => key !== 'date') // ['avg_price', 'revenue', 'reviews', 'star_rating', 'units_sold']

  const keysToSum = []
  const keysToAvg = []
  const keysToAvgCurrency = []
  const keysToAvgRating = []

  dataKeys.forEach(key => {
    const metric = key.split('-')[0]

    switch (metric) {
      case 'revenue':
      case 'units_sold':
        keysToSum.push(key)
        break
      case 'reviews':
        keysToAvg.push(key)
        break
      case 'avg_price':
        keysToAvgCurrency.push(key)
        break
      case 'star_rating':
        keysToAvgRating.push(key)
        break
      default:
        break
    }
  })

  return Object.values(groupedData).map(group => {
    const dateRange = getDateRange(group, interval)

    const mergedGroup = {
      ...sumKeys(group, keysToSum),
      ...avgKeys(group, keysToAvg, true),
      ...avgKeysCurrency(group, keysToAvgCurrency, true),
      ...avgRating(group, keysToAvgRating, true)
    }

    return {
      ...dateRange,
      ...mergedGroup
    }
  })
}

export const calculateGroupAverages = data => {
  return data?.reduce((acc, item, index, array) => {
    let revenue = acc.revenue ? acc.revenue + item.revenue : item.revenue
    let units_sold = acc.units_sold
      ? acc.units_sold + item.units_sold
      : item.units_sold
    let avg_price = acc.avg_price
      ? acc.avg_price + item.avg_price
      : item.avg_price
    let star_rating = acc.star_rating
      ? acc.star_rating + item.star_rating
      : item.star_rating
    let reviews = acc.reviews ? acc.reviews + item.reviews : item.reviews

    if (index === array.length - 1) {
      revenue /= array.length
      units_sold /= array.length
      avg_price /= array.length
      star_rating /= array.length
      reviews /= array.length
    }

    return {
      revenue,
      units_sold,
      avg_price,
      star_rating,
      reviews
    }
  }, {})
}

const getRank = (asinRankData, rankTypeFilter) => {
  const { absolute, organic, sponsored } = asinRankData?.topProducts || {}

  switch (rankTypeFilter) {
    case 'allKeywords':
      return absolute?.rank
    case 'organicOnly':
      return organic?.rank
    case 'sponsoredOnly':
      return sponsored?.rank
    default:
      return absolute?.rank
  }
}

const comparableRank = rank => {
  if (rank === null || rank === undefined) {
    return Infinity
  }
  return rank
}

const isAdOpportunity = (primaryProductRankData, competitorProductRankData) => {
  // Ad Opportunity has to ignore the rank type filter, since we need to look at both types
  const primaryOrganicRank = comparableRank(
    getRank(primaryProductRankData, 'organicOnly')
  )
  const competitorOrganicRanks = competitorProductRankData.map(asinRank =>
    getRank(asinRank, 'organicOnly')
  )
  const competitorSponsoredRanks = competitorProductRankData.map(asinRank =>
    getRank(asinRank, 'sponsoredOnly')
  )

  const competitorsAreNotSponsoring = competitorSponsoredRanks.every(
    rank => rank === null || rank === undefined
  )

  const competitorsAreBeatingPrimaryOrganic = competitorOrganicRanks.every(
    rank => comparableRank(rank) < primaryOrganicRank
  )

  const competitorsAreWellRanked = competitorOrganicRanks.every(
    rank => comparableRank(rank) < 25
  )

  return (
    competitorsAreNotSponsoring &&
    competitorsAreBeatingPrimaryOrganic &&
    competitorsAreWellRanked
  )
}

export const filterKeywords = (
  keywords,
  filter,
  rankTypeFilter,
  tableProducts
) => {
  return keywords.filter(keyword => {
    const primaryProductAsin = tableProducts[0]
    const primaryProductRankData =
      keyword?.asinRanks?.find(
        asinRank => asinRank.asin === primaryProductAsin
      ) || {}
    const primaryProductRank = getRank(primaryProductRankData, rankTypeFilter)
    const comparablePrimaryProductRank = comparableRank(primaryProductRank)

    const competitorProducts = tableProducts.slice(1)
    const competitorProductRankData =
      keyword?.asinRanks?.filter(asinRank =>
        competitorProducts.includes(asinRank.asin)
      ) || []

    const competitorProductsRanks = competitorProductRankData.map(asinRank =>
      getRank(asinRank, rankTypeFilter)
    )
    const comparableCompetitorProductsRanks = competitorProductsRanks.map(
      comparableRank
    )

    switch (filter) {
      case 'shared':
        // All products have a rank
        return primaryProductRank && competitorProductsRanks.every(rank => rank)
      case 'missing':
        // Primary product does not rank, and all competitors have a rank
        return (
          !primaryProductRank &&
          competitorProductsRanks.every(rank => rank) &&
          competitorProductsRanks?.length > 0
        )
      case 'weak':
        // If primary product has a rank, it should be lower than all competitor products ranks
        return (
          primaryProductRank &&
          comparableCompetitorProductsRanks.every(
            rank => rank !== Infinity && rank < primaryProductRank
          )
        )
      case 'strong':
        // Primary product has a rank, and it is higher than all competitor products
        return (
          primaryProductRank &&
          comparablePrimaryProductRank <
            Math.min(...comparableCompetitorProductsRanks)
        )
      case 'advertising':
        return isAdOpportunity(
          primaryProductRankData,
          competitorProductRankData
        )
      case 'all':
      default:
        return true
    }
  })
}

export const sortKeywordsTable = (keywords, sortColumn, sortDirection) => {
  return keywords?.sort((a, b) => {
    const valueA = a[sortColumn]
    const valueB = b[sortColumn]

    if (
      (valueA === null || valueA === undefined) &&
      (valueB === null || valueB === undefined)
    ) {
      return 0
    }

    if (valueA === null || valueA === undefined) {
      return 1
    }

    if (valueB === null || valueB === undefined) {
      return -1
    }

    const nullRankCharacter = '-'
    if (sortDirection === 'asc') {
      if (valueA === nullRankCharacter) return 1
      if (valueB === nullRankCharacter) return -1
      return valueA > valueB ? 1 : -1
    }

    if (valueA === nullRankCharacter) return -1
    if (valueB === nullRankCharacter) return 1
    return valueA < valueB ? 1 : -1
  })
}

export const emptyStateMessage = (filter, competitorsCount) => {
  if (competitorsCount === 0 && filter !== 'all') {
    return i18n.t(
      'competitive_intelligence:KeywordAnalysis.emptyState.noCompetitor',
      'Select at least one competitor to compare product keyword rankings.'
    )
  }

  if (competitorsCount >= 1 && competitorsCount <= 2) {
    return i18n.t(
      'competitive_intelligence:KeywordAnalysis.emptyState.sharedKeywords',
      'Choose competitors that are relevant to your product - they’re likely to share common keywords!'
    )
  }

  if (competitorsCount >= 4 && competitorsCount <= 6 && filter !== 'all') {
    return i18n.t(
      'competitive_intelligence:KeywordAnalysis.emptyState.optimalSelection',
      'For optimal results, try narrowing down your selection of competitors!'
    )
  }

  return i18n.t(
    'generic:EmptyState.noResults.description',
    'Please change your filter criteria and try again'
  )
}

export const getRecommendationText = filter => {
  switch (filter) {
    case 'shared':
      return i18n.t(
        'competitive_intelligence:KeywordAnalysis.Opportunities.filter.sharedRecommendation',
        'These are the most important keywords for which all the selected products compete.'
      )
    case 'missing':
      return i18n.t(
        'competitive_intelligence:KeywordAnalysis.Opportunities.filter.missingRecommendation',
        'The selected competitors think these keywords are valuable, consider targeting these keywords.'
      )
    case 'weak':
      return i18n.t(
        'competitive_intelligence:KeywordAnalysis.Opportunities.filter.weakRecommendation',
        'The primary product is competing for these keywords but is outperformed. Consider improving the strategy to compete more effectively.'
      )
    case 'strong':
      return i18n.t(
        'competitive_intelligence:KeywordAnalysis.Opportunities.filter.strongRecommendation',
        'The primary product is winning for these keywords. The current strategy is effective. Nice Job!'
      )
    case 'advertising':
      return i18n.t(
        'competitive_intelligence:KeywordAnalysis.Opportunities.filter.adOpportunityRecommendation',
        'Competitors have high organic rankings but are not defending their positions with ads. This indicates that these keywords could present an advertising opportunity for you to capitalize on.'
      )
    default:
      return ''
  }
}

export const getMinMaxDatesCalendar = () => {
  const maxDate = moment()
    .subtract(1, 'week')
    .endOf('week')
    .startOf('day')

  const minDate = maxDate
    .clone()
    .subtract(365, 'days')
    .toDate()

  return { minDate, maxDate: maxDate.toDate() }
}

export const formatDate = value => {
  const date = moment(value)

  if (value === undefined || value === null || !date.isValid()) {
    return '-'
  }
  return date.format('MMMM DD, YYYY')
}

export const matchRankTypeFilter = rankTypeFilter => {
  switch (rankTypeFilter) {
    case 'allKeywords':
      return 'absolute'
    case 'organicOnly':
      return 'organic'
    case 'sponsoredOnly':
      return 'sponsored'
    default:
      return 'absolute'
  }
}

export const extendKeywordsWithRankData = (
  keywords,
  products,
  rankTypeFilter,
  variantMetadata
) =>
  keywords.map(keyword => {
    const keywordCopy = { ...keyword }
    if (products?.length) {
      products.forEach((product, index) => {
        const asinRankData = keyword?.asinRanks?.find(asinRank => {
          return asinRank.asin === product.id.split('/')[1]
        })
        const filter = matchRankTypeFilter(rankTypeFilter)

        const { rank, asin, type } = asinRankData?.topProducts?.[filter] || {}
        const topProduct = variantMetadata?.[asin] || {}

        keywordCopy[`productRank-${index}`] = !Number(rank) ? '-' : rank
        keywordCopy[`topProduct-${index}`] = {
          ...topProduct,
          marketplace: keyword.country,
          type,
          isSingleProduct: !product.variantAsinsCount && !product.variantCount
        }
      })
    }
    return keywordCopy
  })

export const isChartTooltipExceedingPage = ({ left }) => {
  const pageBuffer = 20

  return (
    left + CHART_TOOLTIP_CONFIG.width + CHART_TOOLTIP_CONFIG.offset >
    window.innerWidth - pageBuffer
  )
}

export const updateKeywordsCallback = (queryClient, groupId, keyword_list) => {
  queryClient.invalidateQueries(`api/rank_groups/${groupId}/keywords`)
  queryClient.setQueryData(`api/rank_groups/${groupId}`, prevData => {
    return {
      ...prevData,
      data: {
        ...prevData.data,
        keyword_list
      }
    }
  })
}

const calculateRankPosition = rank => {
  if (rank == null) return []
  if (rank <= 5) return ['5', '10', '50']
  if (rank <= 10) return ['10', '50']
  if (rank <= 50) return ['50']
  return []
}

export const dateValueByLabel = label => {
  const endDate = moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD')

  const labelMapping = {
    '7D': {
      label: '7D',
      startDate: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      endDate
    },
    '30D': {
      label: '30D',
      startDate: moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
      endDate
    }
  }

  return labelMapping[label] || labelMapping['7D']
}

const getRankPosition = (keyword, filterType) => {
  const rank = keyword?.[filterType]
  if (rank?.previous != null && rank?.current != null) {
    return {
      previousRankPositions: calculateRankPosition(rank.previous),
      currentRanksPositions: calculateRankPosition(rank.current)
    }
  }
  return { previousRankPositions: [], currentRanksPositions: [] }
}

export function formatRankDistributionData(distributionData, filter) {
  const tracker = {
    '5': {
      id: 'top5',
      name: 5,
      totalKeyword: 0,
      totalNetChange: 0,
      totalGainedChange: 0,
      totalLostChange: 0,
      gainedKeywords: [],
      lostKeywords: []
    },
    '10': {
      id: 'top10',
      name: 10,
      totalKeyword: 0,
      totalNetChange: 0,
      totalGainedChange: 0,
      totalLostChange: 0,
      gainedKeywords: [],
      lostKeywords: []
    },
    '50': {
      id: 'top50',
      name: 50,
      totalKeyword: 0,
      totalNetChange: 0,
      totalGainedChange: 0,
      totalLostChange: 0,
      gainedKeywords: [],
      lostKeywords: []
    }
  }

  if (!distributionData) {
    return []
  }

  distributionData.forEach(keyword => {
    let rankPositions = { previousRankPositions: [], currentRanksPositions: [] }

    switch (filter) {
      case 'organicOnly':
        rankPositions = getRankPosition(keyword, 'organicRank')
        break
      case 'sponsoredOnly':
        rankPositions = getRankPosition(keyword, 'sponsoredRank')
        break
      default:
        rankPositions = getRankPosition(keyword, 'absoluteRank')
        break
    }

    const { previousRankPositions, currentRanksPositions } = rankPositions

    // Skip if either previous or current is empty
    if (
      previousRankPositions.length === 0 ||
      currentRanksPositions.length === 0
    ) {
      return
    }

    currentRanksPositions.forEach(position => {
      tracker[position].totalKeyword += 1
    })

    previousRankPositions.forEach(prevPosition => {
      if (!currentRanksPositions.includes(prevPosition)) {
        tracker[prevPosition].totalLostChange += 1
        tracker[prevPosition].lostKeywords.push(keyword)
      }
    })

    currentRanksPositions.forEach(currPosition => {
      if (!previousRankPositions.includes(currPosition)) {
        tracker[currPosition].totalGainedChange += 1
        tracker[currPosition].gainedKeywords.push(keyword)
      }
    })
  })

  Object.keys(tracker).forEach(position => {
    tracker[position].totalNetChange =
      tracker[position].totalGainedChange - tracker[position].totalLostChange
    tracker[position].totalLostChange = -tracker[position].totalLostChange
  })

  return Object.values(tracker)
}

export const unavailableProductMock = ({ asin, marketplace }) => {
  return {
    imageUrl: null,
    asin,
    category: '-',
    brand: '-',
    isParent: false,
    scrapedParentAsin: '-',
    id: `${marketplace}/${asin}`,
    name: 'Unavailable Product',
    variantAsinsCount: 0,
    categoryCode: '-',
    is_supported_category: false,
    error: 'unavailable_product'
  }
}

export const isUpgradeIncentiveEligible = ({
  billingFrequency,
  status,
  subscriptionDate,
  planId
}) => {
  return (
    billingFrequency === 'monthly' &&
    status === 'active' &&
    moment
      .utc(subscriptionDate)
      .isBetween('2024-04-01', '2024-06-01', 'day', '[)') &&
    [743, 700].includes(planId)
  )
}
